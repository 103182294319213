// firebase-messaging-sw.js

importScripts('https://www.gstatic.com/firebasejs/9.21.0/firebase-app-compat.js');
importScripts('https://www.gstatic.com/firebasejs/9.21.0/firebase-messaging-compat.js');


// Firebase configuration should contain only project identifiers - nothing secret.
firebase.initializeApp({
  apiKey: "AIzaSyCTXgCR1t1P4AfFTb80tMuKlCvjyi29i1c",
  authDomain: "bermad-app.firebaseapp.com",
  projectId: "bermad-app",
  messagingSenderId: "1023221731375",
  appId: "1:1023221731375:web:a67a72d9b093d9add136da"
});

// Initialize Firebase Messaging
const messaging = firebase.messaging();

// Handle background messages
messaging.onBackgroundMessage((payload) => {
  console.log('[firebase-messaging-sw.js] Received background message ', payload);

  // Customize and display the notification
  const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    icon: payload.notification.icon,
    data: payload.data // Only use non-sensitive data
  };

  self.registration.showNotification(notificationTitle, notificationOptions);
});

// Handle notification click events
self.addEventListener('notificationclick', (event) => {
  console.log('[firebase-messaging-sw.js] Notification click received.', event);
  event.notification.close();

  // Safely open the page when clicking the notification
  if (event.notification.data && event.notification.data.action === 'url') {
    event.waitUntil(clients.openWindow(event.notification.data._id));
  }
});
